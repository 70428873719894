"use client";

import Image from "next/image";
import React from "react";
import currency from "@/functions/currency";
import Divider from "../atom/Divider";
import useHitungDiskon from "@/functions/useHitungDiskon";
import Link from "next/link";
import { twMerge } from "tailwind-merge";
import { BlurImage, EmptyImage } from "@/public/images";
import CardSeceleton from "../seceleton/Card";

function checkThumbanil(thumbnail) {
  return !thumbnail ||
    thumbnail === '""' ||
    thumbnail === '"' ||
    thumbnail === "" ||
    thumbnail == "-" ||
    !(thumbnail && (thumbnail.startsWith("http") || thumbnail.startsWith("/")))
    ? EmptyImage
    : thumbnail;
}
export function CardProduct({ id = "", item, imgHeight = "max-h-[420px]" }) {
  const name = item?.name_product;
  const harga = {
    max: item?.price_max,
    min: item?.price_min
  };
  const brand = item?.brand;
  let thumbnail = checkThumbanil(item?.thumbnail);
  const emptyStock = item?.stock <= 0;

  let diskonRange = {
    status: item?.diskon_brand > 0 || item?.diskon_product > 0,
    total: item?.diskon_brand + item?.diskon_product
  };

  // validasi max total diskon persen
  diskonRange.total = diskonRange.total >= 70 ? 70 : diskonRange.total;

  const diskonSale = {
    status: item?.diskon_brand_nominal > 0 || item?.diskon_product_nominal > 0,
    total: item?.diskon_brand_nominal + item?.diskon_product_nominal
  };

  let checkUseDiskon = useHitungDiskon(item, harga?.max);

  const newProduct = item?.new_product;
  return item?._load ? (
    <CardSeceleton />
  ) : (
    <div className="flex flex-col group/card">
      <Link
        aria-label={`${name}`}
        as={`/products/${item?.name_product
          ?.replaceAll("&", "and")
          ?.replaceAll(" ", "-")}-${item?.product_id}`}
        shallow
        passHref
        // target='_blank'
        rel="preload"
        href={`/products/${item?.name_product
          ?.replaceAll("&", "and")
          ?.replaceAll(" ", "-")}-${item?.product_id}`}
        className="relative block aspect-[4/5] w-full overflow-hidden"
      >
        <div className="group flex h-full w-full items-center justify-center overflow-hidden border bg-shadesGON-50 hover:border-primaryGON-400 dark:bg-black relative border-greyscaleGON-200 dark:border-greyscaleGON-800">
          {emptyStock && (
            <div className="absolute h-full w-full  bg-opacity-80 flex items-center justify-center">
              <div className="bg-primaryGON-400 bg-opacity-[0.8] rounded-full w-20 h-20  flex items-center justify-center ">
                <span className="p2-regular text-shadesGON-50 text-opacity-100 ">
                  Habis
                </span>
              </div>
            </div>
          )}
          <Image
            src={thumbnail}
            alt={`nibras-${thumbnail}`}
            className="object-cover w-auto h-auto transition duration-500 group-hover/card:scale-125"
            blurDataURL={BlurImage?.blurDataURL}
            placeholder="blur"
            priority
            quality={85}
            height={320}
            width={320}
          />
        </div>
      </Link>
      <div>
        {newProduct && (
          <div
            className={twMerge(
              `w-fit px-2 py-1 bg-secondaryGON-400 rounded-tr-full rounded-br-full mb-2 mt-3`
            )}
          >
            <p className="text-[10px] xs:p1-regular font-semibold capitalize text-secondaryGON-50 ">
              Terbaru
            </p>
          </div>
        )}
        <div className="flex h-auto justify-end w-full flex-col gap-1 py-1 bg-shadesGON-50 z-10">
          <p className="text-base xs:text-2xl font-work-sans font-medium text-greyscaleGON-700 capitalize max-w-full line-clamp-2">
            {name}
          </p>
          <div className="flex flex-col gap-1">
            <span className="text-base xs:text-xl font-work-sans font-medium text-primaryGON-400 max-w-full whitespace-nowrap overflow-hidden text-ellipsis">
              {currency(checkUseDiskon)}
            </span>
            {(diskonRange?.status || diskonSale?.status) && (
              <div
                className={twMerge(
                  "flex gap-2 pb-2",
                  id == "search"
                    ? "flex-col"
                    : "flex-col sm:flex-row sm:items-center "
                )}
              >
                <span className="p2-regular xs:p1-regular text-greyscaleGON-400 max-w-full whitespace-nowrap overflow-hidden text-ellipsis line-through">
                  {currency(harga?.max)}
                </span>
                <div
                  className={twMerge(
                    `w-fit py-1 bg-secondaryGON-50 px-2 rounded `
                  )}
                >
                  <p className="text-[11px] xs:p1-regular font-semibold capitalize text-secondaryGON-400 ">
                    Disc {diskonRange?.total}%
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Divider className="group/card-focus:bg-primaryGON-400 group/card-hover:bg-primaryGON-400" />
        <Link
          aria-label={`${item?.product_id}-${brand}`}
          as={`/search/brand/${brand}`}
          shallow
          href={`/search/brand/${brand}`}
          rel="preload"
          className="flex justify-start self-start group"
        >
          <span className="p2-regular text-greyscaleGON-700 group-hover:text-primaryGON-400  group-hover:underline group-hover:underline-offset-1 capitalize">
            {brand}
          </span>
        </Link>
      </div>
    </div>
  );
}
