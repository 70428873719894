"use client";

import { ChevronLeftSvg, ChevronRightSvg } from "@/public/svg";
import React, { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

const Carousel = ({
  datas,
  renderItem,
  maxContent = 4,
  mini = false,
  animation = true,
  padding = true,
  offsetIcon = false,
  hiddenIcon = false,
  activeIndex = 0,
  getActiveIndex = () => {}
}) => {
  const [currentData, setCurrentData] = useState(activeIndex);
  const [currentRender, setCurrentRender] = useState(maxContent);

  const goToNextData = (mode) => {
    getActiveIndex((currentData + 1) % datas.length);
    mode === "images"
      ? setCurrentData((prev) => (prev + 1) % datas.length)
      : currentRender >= datas?.length
      ? null
      : setCurrentRender((prev) => prev + maxContent);
  };

  const goToPrevData = (mode) => {
    getActiveIndex((currentData - 1 + datas.length) % datas.length);
    mode === "images"
      ? setCurrentData((prev) => (prev - 1 + datas.length) % datas.length)
      : currentRender <= maxContent
      ? null
      : setCurrentRender((prev) => prev - maxContent);
  };

  useEffect(() => {
    if (activeIndex < 0) return;
    setCurrentData(activeIndex);
  }, [activeIndex]);

  const RenderItem = renderItem;

  const firstSlice = useMemo(() => {
    let val = currentRender - maxContent;
    val = val <= 0 ? 0 : val;

    return val;
  }, [currentRender, maxContent]);

  return (
    <div className="relative group/carousel">
      <div
        className={twMerge(
          !mini ? `grid-product-list` : `grid-product-list-4`,
          "relative w-full mb-2"
        )}
      >
        {datas
          ?.slice(firstSlice, currentRender)
          ?.map((item, index) =>
            !renderItem ? null : (
              <RenderItem key={index} index={index + firstSlice} item={item} />
            )
          )}
        <div
          onClick={() => goToPrevData("render")}
          className={twMerge(
            hiddenIcon && "invisible",
            currentRender <= maxContent && "invisible",
            offsetIcon ? "xs:-left-10 left-0" : "left-0",
            animation &&
              "xs:opacity-0 group-hover/carousel:opacity-100 delay-0 duration-300 ease-in-out",
            "absolute z-30 top-1/2 bg-shadesGON-50 inline-block shadow-normalGON border border-primaryGON-50  rounded-full cursor-pointer "
          )}
        >
          <div className={twMerge(padding && "p-2 xs:p-3 lg:p-5")}>
            <ChevronLeftSvg className="text-primaryGON-400 h-4 w-4 xs:h-6 xs:w-6" />
          </div>
        </div>
        <div
          onClick={() => goToNextData("render")}
          className={twMerge(
            hiddenIcon && "invisible",
            currentRender >= datas?.length && "invisible",
            offsetIcon ? "xs:-right-10 right-0" : "right-0",
            animation &&
              "xs:opacity-0 group-hover/carousel:opacity-100 delay-0 duration-300 ease-in-out",
            "absolute z-30 top-1/2 bg-shadesGON-50 inline-block shadow-normalGON border border-primaryGON-50  rounded-full cursor-pointer"
          )}
        >
          <div className={twMerge(padding && "p-2 xs:p-3 lg:p-5")}>
            <ChevronRightSvg className="text-primaryGON-400 h-4 w-4 xs:h-6 xs:w-6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
