export default function useHitungDiskon(datas, price) {
  let diskonPersentase = {
    status: datas?.diskon_brand > 0 || datas?.diskon_product > 0,
    total: datas?.diskon_brand + datas?.diskon_product,
  };

  // validasi max total diskon persen
  diskonPersentase.total =
    diskonPersentase.total >= 70 ? 70 : diskonPersentase.total;

  const total_harga_persentase = Math.round(
    parseFloat(
      !diskonPersentase?.status
        ? price
        : ((100 - diskonPersentase?.total) / 100) * price
    )
  );

  const diskonRupiah = {
    status:
      datas?.diskon_brand_nominal > 0 || datas?.diskon_product_nominal > 0,
    total: datas?.diskon_brand_nominal + datas?.diskon_product_nominal,
  };

  const total_harga_rupiah = Math.round(
    parseFloat(
      !diskonRupiah?.status
        ? price
        : price - diskonRupiah?.total > 0
        ? price - diskonRupiah?.total
        : 0
    )
  );

  return diskonPersentase?.status ? total_harga_persentase : total_harga_rupiah;
}
