"use client";

import { TitleAction } from "../sub-atom/InputTitle";
import { CardProduct } from "../sub-atom/Card";
import { memo, useMemo, useState } from "react";
import Carousel from "./Carousel";
import Button from "./Button";
import {
  useCheckBreakPoint,
  useCheckBreakPointCustom
} from "@/functions/usResponsive";

function ProductList({
  title,
  handleAction = {
    title: "",
    onclick: () => {},
    icon: undefined,
    show: true
  },
  horizontal = false,
  pageSize = 15,
  withoutPageSize = false,
  grid = "grid-product-list",
  datas = [],
  handleNextPage = {
    show: true,
    num: 1,
    loading: false,
    disabled: false,
    nextPage: () => {}
  }
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return withoutPageSize
      ? handleNextPage?.loading
        ? datas?.length > 0
          ? [...datas, ...Array.from({ length: 4 }, () => ({ _load: true }))]
          : Array.from({ length: 4 }, () => ({ _load: true }))
        : datas?.filter((val) => !val?._load)
      : datas?.slice(firstPageIndex, lastPageIndex);
  }, [pageSize, currentPage, datas, handleNextPage?.loading]);

  let breakpoint = useCheckBreakPoint();
  let breakpointCustom = useCheckBreakPointCustom();

  breakpoint = breakpointCustom?.length > 0 ? "ui-laptop" : breakpoint;

  return (
    datas.length > 0 && (
      <div id={title} className="flex flex-col gap-1 xs:gap-4 bg-shadesGON-50">
        {horizontal ? (
          <>
            {breakpoint == "ui-laptop" ? (
              <div className="flex flex-col gap-1 xs:gap-4">
                {!title ? null : (
                  <TitleAction
                    title={title}
                    handleAction={{
                      ...handleAction,
                      show: datas?.length <= 4 ? false : true
                    }}
                  />
                )}
                <Carousel
                  datas={datas}
                  offsetIcon
                  maxContent={4}
                  renderItem={({ item }, index) => (
                    <CardProduct key={index} id={title} item={item} />
                  )}
                />
              </div>
            ) : breakpoint == "ui-tablet" ? (
              <div className="flex flex-col gap-1 xs:gap-4">
                {!title ? null : (
                  <TitleAction
                    title={title}
                    handleAction={{
                      ...handleAction,
                      show: datas?.length <= 4 ? false : true
                    }}
                  />
                )}
                <Carousel
                  datas={datas}
                  offsetIcon
                  maxContent={4}
                  renderItem={({ item }, index) => (
                    <CardProduct key={index} item={item} />
                  )}
                />
              </div>
            ) : breakpointCustom == "ui-tablet-mini" ? (
              <div className="flex flex-col gap-1 xs:gap-4">
                {!title ? null : (
                  <TitleAction
                    title={title}
                    handleAction={{
                      ...handleAction,
                      show: datas?.length <= 3 ? false : true
                    }}
                  />
                )}
                <Carousel
                  datas={datas}
                  offsetIcon
                  maxContent={3}
                  renderItem={({ item }, index) => (
                    <CardProduct key={index} item={item} />
                  )}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-1 xs:gap-4">
                {!title ? null : (
                  <TitleAction
                    title={title}
                    handleAction={{
                      ...handleAction,
                      show: datas?.length <= 2 ? false : true
                    }}
                  />
                )}
                <Carousel
                  datas={datas}
                  offsetIcon
                  maxContent={2}
                  renderItem={({ item }, index) => (
                    <CardProduct key={index} item={item} />
                  )}
                />
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col gap-1 xs:gap-4">
            {!title ? null : (
              <TitleAction
                title={title}
                handleAction={{
                  ...handleAction,
                  show: withoutPageSize
                    ? true
                    : datas?.length <= pageSize + 1
                    ? false
                    : true
                }}
              />
            )}
            <div className="flex flex-col gap-3 xs:gap-6 justify-center pb-5">
              <div className={grid}>
                {currentTableData?.map((item, index) => (
                  <CardProduct item={item} key={index} itemLoading={true} />
                ))}
              </div>
              {handleNextPage?.show && (
                <div className="flex w-full justify-center items-center py-10">
                  <Button
                    className="min-w-[12px]"
                    disable={handleNextPage?.disabled}
                    loading={handleNextPage?.loading}
                    variants="outline"
                    label={`Muat Lebih Banyak`}
                    onClick={() =>
                      handleNextPage?.nextPage &&
                      handleNextPage?.nextPage(handleNextPage?.num + 1)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default memo(ProductList);
